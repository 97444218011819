@font-face {
  font-family: 'BlissM';
  src: local('BlissM'), url(./fonts/blissM.TTF) format('truetype');
}
@font-face {
  font-family: 'Borel';
  src: local('BlissM'), url(./fonts/Borel-Regular.ttf) format('truetype');
}
.title-medium {
  font-family: 'BlissM';
}

body {
  font-family: 'BlissM';
  background: #0b844e;
}
